<template>
  <page-view
    class="brand-side"
    v-infinite-scroll="loadScroll"
    :infinite-scroll-disabled="disabled"
    :infinite-scroll-immediate="false"
    :up-class="'brand-side'"
  >
    <div class="banner-img">
      <el-carousel
        trigger="click"
        arrow="always"
        :interval="2000"
        ref="carouselRef"
      >
        <el-carousel-item v-for="item in funList" :key="item.id">
          <img v-lazy="item.image" width="100%" height="100%" alt="" />
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="info-box">
        <div class="info-describe">深度挖掘商展独特价值打造产业数字化平台</div>
        <div class="info-name-box">
          <div class="info-name">
            <span class="name-i">百川</span>智慧商展<span class="name-i2"
              >产业数字化平台</span
            >
          </div>
          <div class="info-name-line">
            <div class="line"></div>
            <div class="point"></div>
            <div class="line"></div>
          </div>
        </div>
        <el-popover placement="bottom" popper-class="info-popo" trigger="click">
          <div class="info-code">
            <p>商务合作：18923886047</p>
            <img src="@/assets/img/info_code.jpg" alt="" />
          </div>
          <div slot="reference" class="info-btn">商务合作</div>
        </el-popover>
      </div> -->
    </div>

    <div class="test-img"></div>
    
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import Waterfall from "@/components/waterfall/waterfall.vue";
import { mapState } from "vuex";
export default {
  name: "BrandSide",
  components: { Waterfall },
  data() {
    return {
      rotateNum: 0,
      startx: 0,
      funList: [],
      rafId: null,
      startDown: null,
      comList: [],
      waterData: [],
      loading: false,
      current: 1,
      total: 0,
      showUp: true,
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
    noMore() {
      return this.waterData.length >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.selectSn();
    this.selectSnAll();
    this.getBanner()
  },
  mounted() {
    // this.onRotate();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.rafId);
    this.rafId = 0;
  },
  deactivated() {
    this.showUp = false;
    this.$nextTick(() => {
      this.showUp = true;
    });
  },
  methods: {
    async getBanner(){
      let res = await this.$api.getBanner('PT00008')
      if(res && res.itemList.length > 0){
        this.funList = res.itemList
      }
    },
    // 获取推荐品牌方
    async selectSn() {
      let result = await this.$api.selectSn({
        bc_company_type: 1,
        current: 1,
        size: 30,
        s1: 0,
        s3: 0,
      });
      this.comList = result.data;
    },
    //获取全部品牌方
    async selectSnAll() {
      let result = await this.$api.selectSn({
        bc_company_type: 1,
        current: 1,
        size: 10,
        s1: 0,
      });
      this.total = result.page.total;
      this.$nextTick(() => {
        this.waterData = result.data;
      });
    },
    async loadScroll() {
      if (this.disabled) {
        return false; //没有更多了
      }
      this.loading = true;
      this.current++;
      let result = await this.$api.selectSn({
        bc_company_type: 1,
        current: this.current,
        size: 10,
        s1: 0,
      });
      this.loading = false;
      this.waterData.push(...result.data);
    },
    toComIndex(item) {
      let syscompanyid = item.syscompanyid;
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid },
      });
      window.open(openRoute.href, "_blank");
    },
    onRotate() {
      this.rafId = requestAnimationFrame(() => {
        this.rotateNum += 0.1;
        if (this.rotateNum > 360) {
          this.rotateNum = 0;
        }
        this.onRotate();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-side {
  .banner-img {
    height: 27vw;
    position: relative;
    // @media screen and (max-width: 1200px) {
    //   height: 3.37rem; 
    // }

    :deep(.el-carousel) {
      .el-carousel__container {
        height: 27vw;

        .el-carousel__arrow {
          font-size: 28px;
          @include flexbox(row, center, center);
          height: 40px;
          width: 40px;
        }

        // @media screen and (max-width: 1200px) {
        //   height: 3.37rem;
        // }
      }
    }

    // .info-box {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   z-index: 2;
    //   @include flexbox(column, flex-start, center);

    //   .info-name-box {
    //     margin-top: 10px;
    //     margin-bottom: 20px;

    //     .info-name-line {
    //       @include flexbox(row, center, center);
    //       margin-top: 5px;

    //       .line {
    //         flex: 1;
    //         overflow: auto;
    //         height: 1px;
    //         background-color: #333;
    //       }

    //       .point {
    //         // width: 20px;
    //         // @include flexbox(row,center,center);
    //         // line-height: 1;
    //         // font-size: 32px;

    //         width: 4px;
    //         height: 4px;
    //         border-radius: 50%;
    //         background-color: #000;
    //         margin: 0 10px;
    //       }
    //     }
    //   }

    //   .info-name {
    //     font-weight: bold;
    //     color: #2f3b44;
    //     font-size: 58px;
    //     @media screen and (max-width: 1200px) {
    //       font-size: 0.50rem;
    //       white-space: pre;
    //     }

    //     .name-i {
    //       background: linear-gradient(to bottom right, #e27034, #932023);
    //       -webkit-background-clip: text;
    //       color: transparent;
    //       display: inline-block;
    //     }

    //     .name-i2 {
    //       background: linear-gradient(to bottom, #e27034, #2f3b44);
    //       -webkit-background-clip: text;
    //       color: transparent;
    //       display: inline-block;
    //     }
    //   }

    //   .info-describe {
    //     color: #7c7c7c;
    //     font-size: 16px;
    //     font-weight: bold;
    //     letter-spacing: 4px;
    //     @media screen and (max-width: 1200px) {
    //       font-size: 0.24rem;
    //       white-space: pre;
    //     }
    //   }

    //   .info-btn {
    //     font-size: 16px;
    //     color: #e27034;
    //     letter-spacing: 2px;
    //     border: 2px solid #e27034;
    //     padding: 10px 35px;
    //     border-radius: 50px;
    //     cursor: pointer;
    //   }
    // }
  }

  .test-img{
    background: url(../../assets/img/zhengti.jpg) no-repeat center center;
    background-size: 100% 100%;
    height: 60vw;
    border-top: 20px solid #fff;
  }
  .brand-list {
    padding: 4vw 0;
    background: radial-gradient(#2c6297, #171d27);
    .brand-title {
      .container-tltle {
        margin-bottom: 10px !important;
        color: #fff;
      }
    }
  }

  .goods-water {
    background: radial-gradient(#2c6297, #171d27);
    padding: 50px 0;
    padding-bottom: 150px;

    .goods-content {
      width: 80%;
      margin: 0 auto;
      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      :deep(.box) {
        width: 229px;
      }
    }
    .bouncing-bar {
      display: flex;
      justify-content: center;
      .line {
        height: 20px;
        width: 4px;
        background: #439c4c;
        margin-right: 4px;
        border-radius: 6px;
        animation: line-bounce 1s infinite ease-in-out;

        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          animation-delay: 0.3s;
        }
        &:nth-child(4) {
          animation-delay: 0.4s;
        }
      }
    }

    @keyframes line-bounce {
      0% {
        transform: scale(1);
      }
      20% {
        transform: scale(1, 2);
      }
      40% {
        transform: scale(1);
      }
    }
  }
}

.info-popo {
  .info-code {
    text-align: center;
    padding-top: 10px;

    > img {
      width: 150px;
    }
  }
}
</style>